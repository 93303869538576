.uploadCsv {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  padding-top: 4rem;

  & > .leftContainer {
    width: 100%;

    & > .mainCard {
      padding: 0;

      & > .topCard {
        background: var(--light-green-color);
        height: 8rem;
      }

      & > .bottomCard {
        padding: 1rem 1.5rem;
        position: relative;
        background: white;
        padding-top: 6.5rem;
        display: flex;
        gap: 1.2rem;

        & > img {
          height: 8rem;
          width: 8rem;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 5.5rem;
          transform: translate(-50%, -50%);
        }

        & > .closeBtn {
          padding: 0.6rem 1.5rem;
          border-radius: 35px;
          background: var(--main-color);
          color: white;
          font-size: 0.85rem;
          font-weight: 500;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0rem;
          transform: translate(-50%, -50%);
          transition: all linear 0.2s;

          &:hover {
            scale: 0.97;
          }
        }

        & > .leftCard {
          width: 40%;

          & > .uploadContainer,
          .contentInput {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 2rem;

            & > .title {
              font-size: 1.3rem;
              font-weight: 550;
              margin-bottom: 0.5rem;
            }

            & > .uploadBtn {
              border: 1px solid #e5e5e5;
              padding: 0.7rem 1.5rem;
              border-radius: 35px;
              cursor: pointer;
              text-align: center;
              width: 350px;
              font-size: 0.9rem;
              font-weight: 500;

              &:hover {
                color: white;
                background: var(--light-green-color);
              }

              & > input {
                display: none;
              }
            }

            & > .selectedOption {
              border-color: var(--light-green-color);
            }

            & > .inputContainer {
              border: 1px solid #e5e5e5;
              padding: 0.7rem;
              border-radius: 8px;
              height: 6rem;
              text-align: start;
            }
          }

          & > .contentInput {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            // margin-top: 2.2rem;

            & > .allAccounts {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              & > div {
                display: flex;
                align-items: center;
                gap: 1rem;

                & > div {
                  border: 1px solid #e5e5e5;
                  border-radius: 8px;
                  padding: 0.7rem 1rem;
                  width: 300px;
                  font-size: 0.9rem;
                }

                & > .inputField {
                  width: 300px;
                  padding: 0.7rem 1rem;
                  border-radius: 8px;
                  border: 1px solid #e5e5e5;
                }

                & > .addNew {
                  cursor: pointer;
                  padding: 0.7rem 1.2rem;

                  &:hover {
                    background: var(--light-green-color);
                    color: white;
                  }
                }

                & > svg {
                  color: #ff5959;
                  cursor: pointer;
                }
              }
            }
          }

          & > .submitBtn {
            padding: 0.6rem 1.5rem;
            border-radius: 6px;
            background: var(--light-green-color);
            color: var(--font-color);
            font-size: 0.95rem;
            font-weight: 500;
            cursor: pointer;
            width: max-content;
            margin: 2rem 0;
            font-weight: 550;
            transition: all linear 0.2s;

            &:hover {
              scale: 0.97;
            }
          }

          & > .mainLoading {
            position: fixed;
            inset: 0;
            pointer-events: auto;
            z-index: 5;
            background: rgba(128, 128, 128, 0.1);

            & > * {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .chatBot {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            & > .eachMessage {
              & > .title {
                font-size: 1.2rem;
                font-weight: 550;
                margin-bottom: 7px;
                color: var(--light-green-color);
              }

              & > .message {
                font-weight: 350;
                line-height: 1.5;
              }
            }
          }
        }

        & > .allCards {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 60%;

          & > .outputCard {
            border: 1px solid #f5f5f5;
            border-radius: 6px;

            & > .title {
              font-weight: 510;
              padding: 0.7rem;
              margin-bottom: 1rem;
            }

            & > .allDocs {
              display: flex;
              gap: 1rem;
              align-items: center;
              overflow-x: scroll;
              width: 100%;

              & > div {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                padding: 0.7rem 1rem;
                border-bottom: 2px solid transparent;
                white-space: nowrap;
              }

              & > .selectedTab {
                border-bottom: 2px solid var(--light-green-color);
              }
            }

            & > .allOptions {
              padding: 1rem;
              display: flex;
              justify-content: space-between;

              & > .searchContainer {
                border-radius: 6px;
                padding: 4px 1rem;
                outline: none;
                font-size: 0.85rem;
                border: 0.5px solid #e5e5e5;

                &:focus {
                  border: 0.5px solid var(--light-green-color);
                }
              }

              & > .btnsContainer {
                display: flex;
                align-items: center;
                gap: 1rem;

                & > .downloadBtn {
                  padding: 0.7rem 1.2rem;
                  font-size: 0.9rem;
                  font-weight: 450;
                  cursor: pointer;
                  background: var(--light-green-color);
                  color: var(--font-color);
                  width: max-content;
                  border-radius: 35px;
                  display: flex;
                  align-items: center;
                  gap: 0.35rem;
                  border: 0.5px solid var(--light-green-color);
                  transition: all linear 0.2s;

                  &:hover {
                    scale: 0.97;
                  }
                }
              }
            }

            & > .eachDoc {
            }
          }
        }
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 4rem 0;
  }

  .boxContainer {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    padding: 1rem;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .notAllowed {
    pointer-events: none;
    opacity: 0.6;
  }
}

.csvTable {
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0.7rem;

  & > .tableContainer {
    overflow-x: scroll;

    & > table {
      border-spacing: 20px;
      border-collapse: collapse;

      tr {
        border: 1px solid #f5f5f5;
      }

      th,
      td {
        padding: 7px;
      }
    }

    thead {
      text-align: left;
      white-space: nowrap;
    }

    input {
      border: none;
      outline: none;
      border: 1px solid transparent;

      &:focus {
        border-color: var(--light-green-color);
      }
    }
  }

  & > ul {
    display: flex;
    align-items: center;
    gap: 10px;
    list-style: none;
    margin: auto;
    width: max-content;
    margin-top: 1rem;

    & > li {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 0.5px solid var(--light-green-color);
      cursor: pointer;
    }

    & > :nth-child(1) {
      padding: 0.4rem 1rem;
      background: var(--light-green-color);
      color: var(--font-color);
      border-radius: 35px;
      width: unset;
      height: unset;
    }

    & > :last-child {
      padding: 0.4rem 1rem;
      background: var(--light-green-color);
      color: var(--font-color);
      border-radius: 35px;
      width: unset;
      height: unset;
    }

    .active {
      background: var(--light-green-color);
    }
  }
}

@media screen and (max-width: 565px) {
  .uploadCsv {
    padding: 0 0.5rem;
    padding-top: 7rem;

    & > div {
      width: 100% !important;
    }

    & > .leftContainer {
      padding-top: 0;

      & > .boxContainer {
        & > .bottomCard {
          flex-direction: column;

          & > .leftCard,
          .allCards {
            width: 100%;
          }
        }
      }
    }
  }
}
