.marketplace {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  position: relative;

  & > .mainText {
    font-size: 3.5rem;
    font-weight: 550;
    text-align: center;
    margin-top: 3rem;
  }

  & > .allCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
    margin-top: 1rem;

    & > div {
      padding: 1.5rem;
      border-radius: 26px;
      aspect-ratio: 1/1.3;
      background: var(--light-green-color);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 1.5rem;

      & > :nth-child(1) {
        font-size: 2rem;
        font-weight: 550;
        min-height: 8rem;
      }

      & > :nth-child(2) {
        font-size: 1.1rem;
        line-height: 1.5;
        min-height: 6rem;
      }
    }
  }

  & > .marketPlaceCard {
    background: var(--green-color);
    padding: 0.7rem 2rem;
    border-radius: 35px;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 500;
    rotate: -7deg;
    animation: moveUpAndDown 2s alternate infinite;
  }

  @keyframes moveUpAndDown {
    from {
      top: 0;
    }
    to {
      top: 3rem;
    }
  }
}

@media screen and (max-width: 565px) {
  .marketplace {
    padding: 4rem 10px;

    & > .allCards {
      grid-template-columns: repeat(1, 1fr);

      & > div {
        aspect-ratio: 1/1.1;

        & > :nth-child(1),
        :nth-child(2) {
          min-height: unset;
        }
      }
    }
  }
}
