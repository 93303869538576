.landing {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-top: 7rem;

  & > .mainText {
    font-size: 3.5rem;
    font-weight: 550;
    text-align: center;
  }

  & > .desc {
    font-size: 1.2rem;
    text-align: center;
  }

  & > div {
    width: 100%;

    & > .searchByAgent {
      width: 100%;
      & > input {
        padding: 1rem;
        // width: 500px;
        border: 1px solid #f5f5f5;
        background: white;
        border-radius: 6px;
        outline: none;
        width: 100%;
        font-size: 1.3rem;
        font-weight: 450;

        &:focus {
          border-color: var(--light-green-color);
        }
      }
    }

    & > .filterByAgent {
      width: 100%;
      display: flex;
      gap: 1.2rem;
      margin-top: 1rem;

      // & > div {
      //   padding: 1rem;
      //   // border: 1px solid #f5f5f5;
      //   background: white;
      //   border-radius: 6px;
      //   outline: none;
      //   color: #b9b8b8;
      //   font-weight: 350;
      //   font-size: 0.85rem;
      // }
    }
  }
}

@media screen and (max-width: 565px) {
  .landing {
    padding: 4rem 10px;
    padding-top: 7rem;

    & > .mobileContainer {
      & > .hireCard {
        left: -2rem;
        top: -2.5rem;
      }

      & > .starCard {
        right: -1.5rem;
        bottom: 15%;
      }
    }

    & > div {
      & > .filterByAgent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        & > div {
          min-width: unset;
        }
      }
    }
  }
}
